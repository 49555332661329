import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { useCallback } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "components/common/utils/useDebounce";
import { actions, pages } from "redux/reducers/startPageReducer";
function useStartPageContext(_ref) {
  var page = _ref.page,
    _ref$enableStorage = _ref.enableStorage,
    enableStorage = _ref$enableStorage === void 0 ? false : _ref$enableStorage,
    defaultState = _ref.defaultState;
  var dispatch = useDispatch();
  var router = useRouter();
  var _router$query = router.query;
  _router$query = _router$query === void 0 ? {} : _router$query;
  var clear = _router$query.clear;
  var getContext = useCallback(function () {
    var _useSelector$page;
    var defaults = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var state = _objectSpread(_objectSpread({}, defaults), (_useSelector$page = useSelector(pages)[page]) !== null && _useSelector$page !== void 0 ? _useSelector$page : {});
    var setState = function setState(pageProps) {
      dispatch(actions.update(_objectSpread(_objectSpread(_objectSpread({}, defaults), pageProps), {}, {
        page: page,
        enableStorage: enableStorage
      })));
    };
    var call = useDebounce(function (promise) {
      setState({
        status: "pending"
      });
      Promise.resolve(promise).then(function (response) {
        // @TODO: Add proper condition to check the "NeedAuth" status.
        // if (authentication) {
        //   setState({
        //     status: "needauth",
        //   });
        // } else {
        setState(_objectSpread({
          status: "done"
        }, response));
        // }
      })["catch"](function (error) {
        console.error("error:", error);
        setState({
          status: "error",
          error: error
        });
      });
    }, 50);
    if (clear === true) {
      var _window;
      dispatch(actions.clear({
        page: page,
        enableStorage: enableStorage
      }));
      var _url = new URL((_window = window) === null || _window === void 0 ? void 0 : _window.location);
      _url.searchParams.set("clear", "false");
      router.replace(_url);
    }
    if (enableStorage && clear !== true && !(state !== null && state !== void 0 && state.isSynced)) {
      dispatch(actions.sync(page));
    }
    return {
      state: state,
      setState: setState,
      call: call,
      page: page
    };
  }, [page]);
  return getContext;
}
export default useStartPageContext;