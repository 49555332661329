var apiKey = "d88f5387-b626-4f6a-62e6-e82534a961cf";
var Fingerprint2;
if (typeof window !== "undefined") {
  Fingerprint2 = require("@fingerprintjs/fingerprintjs/fingerprint2");
}
var options = {
  apiKey: apiKey,
  visitor: {
    id: "",
    // Required if user is logged in
    logged_in: false,
    email: "ANON",
    databases: "",
    addDate: 0,
    browserWidth: 0,
    browserHeight: 0
  },
  account: {
    id: "",
    name: "",
    orgtype: "Other"
  }
};
var initState = false;

// init snippet
if (typeof document !== "undefined") {
  (function (p, e, n, d, o) {
    var v, w, x, y, z, t;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ["initialize", "identify", "updateOptions", "pageLoad"];
    for (w = 0, x = v.length; w < x; ++w) {
      (function (m) {
        o[m] = o[m] || function () {
          o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
        };
      })(v[w]);
    }
    y = e.createElement(n);
    y.async = !0;
    y.src = "https://cdn.pendo.engineeringvillage.com/agent/static/" + apiKey + "/pendo.js";
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
    t = e.createElement("div");
    t.id = "ev-pendo-session-time-wrapper";
    e.getElementsByTagName("body")[0].append(t);
  })(window, document, "script", "pendo");
}
function getWidth() {
  return Math.max(document.body.scrollWidth, document.documentElement.scrollWidth, document.body.offsetWidth, document.documentElement.offsetWidth, document.documentElement.clientWidth);
}
function getHeight() {
  return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.documentElement.clientHeight);
}
function pendoInit(usersession) {
  if (initState) {
    return;
  }
  initState = true;
  if (usersession && typeof usersession != "undefined" && usersession.user && typeof usersession.user != "undefined") {
    if (typeof pendo != "undefined") {
      options.visitor.logged_in = "INDIVIDUAL" === usersession.user.userAnonymity;
      options.visitor.email = options.visitor.logged_in ? usersession.user.email : "ANON";
      options.visitor.databases = usersession.userEntitlements.filter(function (entitlement) {
        if (entitlement.entitlementType == "DATABASE") return true;
      }).map(function (db) {
        return db.name;
      }).toString();
      options.visitor.browserWidth = getWidth();
      options.visitor.browserHeight = getHeight();
      options.account.id = usersession.user.account.accountId;
      options.account.name = usersession.user.account.accountName;
      options.account.orgtype = usersession.user.account.orgType;
      if (options.visitor.logged_in) {
        options.visitor.id = usersession.user.webUserId;
        if (typeof usersession.userProfile.addDate != "undefined") {
          options.visitor.addDate = parseInt(usersession.userProfile.addDate);
        }
        pendo.initialize(options);
      } else if (typeof Fingerprint2 != "undefined") {
        new Fingerprint2({
          detectScreenOrientation: false,
          excludeScreenResolution: true,
          excludeAvailableScreenResolution: true
        }).get(function (result) {
          options.visitor.id = result + "_" + usersession.user.account.accountNumber;
          options.visitor.cookieDomain = ".engineeringvillage.com";
          pendo.initialize(options);
        });
      }
    } else {
      console.error("pendo object not found!");
    }
  } else {
    console.error("usersession and user objects required!");
  }
}
export default pendoInit;