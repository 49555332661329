import _objectWithoutProperties from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["database"];
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import router from "next/router";
import { selectUsersessionData } from "redux/reducers/userSessionReducer";
import { selectSessionSettings, updateSessionSettings } from "redux/reducers/sessionSettingsReducer";
import { Databases, getSelectedDatabasesMasks, isValidMask } from "components/applogic/Databases";
import useQuickAndExpertContext from "contexts/StartPage/useQuickAndExpertContext";
import { getNobackfile, getAllDbValue, getDatabases, isEntitled as _isEntitled, removeEncompass } from "./tools";
function useDatabases() {
  var userSession = useSelector(selectUsersessionData);
  var sessionSettings = useSelector(selectSessionSettings);
  var dispatch = useDispatch();
  var _useQuickAndExpertCon = useQuickAndExpertContext(),
    setQuickAndExpertState = _useQuickAndExpertCon.setQuickAndExpertState;
  var userMask = sessionSettings.userMask;
  var userEntitlements = userSession.userEntitlements,
    enabledFences = userSession.enabledFences;
  var cleanedUserEntitlements = removeEncompass(userEntitlements);
  var nobackfile = getNobackfile(cleanedUserEntitlements);
  var allDBValue = getAllDbValue({
    nobackfile: nobackfile
  });
  var userSelectedDBs = getSelectedDatabasesMasks(userMask);
  var defaultDBs = getDatabases({
    nobackfile: nobackfile,
    enabledFences: enabledFences
  });
  var cpxZbf = _isEntitled(Databases.cpx.mask, userEntitlements) && _isEntitled(Databases.zbf.mask, userEntitlements);
  var insIbs = _isEntitled(Databases.ins.mask, userEntitlements) && _isEntitled(Databases.ibs.mask, userEntitlements);
  var addFrontBackFile = function addFrontBackFile(maskIn) {
    var maskOut = maskIn;
    // eslint-disable-next-line no-bitwise
    if (cpxZbf & maskIn & Databases.cpx.mask) {
      // eslint-disable-next-line no-bitwise
      maskOut |= Databases.zbf;
    }
    // eslint-disable-next-line no-bitwise
    if (insIbs & maskIn & Databases.ins.mask) {
      // eslint-disable-next-line no-bitwise
      maskOut |= Databases.ibs;
    }
    return maskOut;
  };
  useEffect(function () {
    if (router.query.database !== undefined && router.query.database !== "" && isValidMask(Number(router.query.database))) {
      // EV-14550 - add check for db entitlement before updating db mask
      var _dbMaskNum = Number(router.query.database);
      dispatch(updateSessionSettings({
        // eslint-disable-next-line no-bitwise
        userMask: (_dbMaskNum & allDBValue) === _dbMaskNum ? _dbMaskNum : allDBValue
      }));
    } else if (+userMask === 0) {
      dispatch(updateSessionSettings({
        userMask: allDBValue
      }));
    }
    if (router.query.database !== undefined) {
      // disable lint rule for database because declared just for removal
      // eslint-disable-next-line no-unused-vars
      var _router$query = router.query,
        database = _router$query.database,
        queryWithoutDatabase = _objectWithoutProperties(_router$query, _excluded);
      router.push({
        pathname: router.pathname,
        query: queryWithoutDatabase
      });
    }
  }, [userMask]);
  var onUpdate = function onUpdate(value) {
    var newMask = addFrontBackFile(value || allDBValue);
    setQuickAndExpertState({
      databasemask: newMask
    });
    dispatch(updateSessionSettings({
      userMask: newMask
    }));
  };
  var all = +allDBValue === +userMask;
  return {
    allDBValue: allDBValue,
    defaultDBs: defaultDBs,
    userMask: Number(userMask),
    isEntitled: function isEntitled(mask) {
      return _isEntitled({
        mask: mask,
        userEntitlements: userEntitlements
      });
    },
    onUpdate: onUpdate,
    initial: {
      select: userSelectedDBs,
      all: all
    },
    select: useState(userSelectedDBs),
    all: useState(all)
  };
}
export default useDatabases;