export var resultsPerPageOptions = [25, 50, 100];
export var sortDefaultOptions = [{
  label: "Relevance",
  value: "relevance"
}, {
  label: "Date (Newest)",
  value: "yr"
}];
export var showPreviewOptions = [{
  label: "Hide all",
  value: false
}, {
  label: "Show all",
  value: true
}];
export var facetOrderOptions = [{
  value: "select",
  label: "Select a facet"
}, {
  value: "oanav",
  label: "Open Access"
}, {
  value: "afnav",
  label: "Author affiliation/Assignee"
}, {
  value: "aunav",
  label: "Author/Inventor"
}, {
  value: "cvnav",
  label: "Controlled vocabulary/terms"
}, {
  value: "conav",
  label: "Country/Region"
}, {
  value: "dtnav",
  label: "Document type"
}, {
  value: "lanav",
  label: "Language"
}, {
  value: "yrnav",
  label: "Year"
}];
export var highlightOptions = ["#ffffaa", "#000000"];
export var advancedDisplayOptions = [{
  value: "closed",
  label: "Closed"
}, {
  value: "database",
  label: "Databases"
}, {
  value: "date",
  label: "Date"
}, {
  value: "doctype",
  label: "Document"
}, {
  value: "sort",
  label: "Sort by"
}, {
  value: "language",
  label: "Language"
}, {
  value: "treatmentType",
  label: "Treatment"
}, {
  value: "disciplinetype",
  label: "Discipline"
}, {
  value: "autostem",
  label: "Autostemming"
}];
export var downloadLocationOptions = [{
  value: "mypc",
  label: "My PC"
}, {
  value: "mendeley",
  label: "*Mendeley"
},
// { value: "refworks", label: "*RefWorks" },
{
  value: "googledrive",
  label: "Google Drive"
}, {
  value: "dropbox",
  label: "Dropbox"
}, {
  value: "tofolder",
  label: "*Your Folder(s)"
}];
export var downloadOutputOptions = [{
  value: "default",
  label: "Current view"
}, {
  value: "citation",
  label: "Citation"
}, {
  value: "abstract",
  label: "Abstract"
}, {
  value: "detailed",
  label: "Detailed"
}];
export var downloadFormatOptions = [{
  value: "ris",
  label: "EndNote (RIS, Ref. Manager)"
}, {
  value: "bib",
  label: "BibTeX"
}, {
  value: "ascii",
  label: "Text (ASCII)"
}, {
  value: "csv",
  label: "CSV"
}, {
  value: "excel",
  label: "Excel&#174;"
}, {
  value: "pdf",
  label: "PDF"
}, {
  value: "rtf",
  label: "RTF (Word&#174;)"
}];