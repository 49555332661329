import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _slicedToArray from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var _excluded = ["fields", "filter"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectSessionSettings } from "redux/reducers/sessionSettingsReducer";
import { getSelectedDatabaseCodes } from "components/applogic/Databases";
var PAGE_MASKS = {
  Quick: "qsmask",
  Expert: "esmask"
};
export function intersection(array1, array2) {
  return array1.filter(function (value) {
    return array2.includes(value);
  });
}
export function filterFunctionByCode(_ref) {
  var databaseCodes = _ref.databaseCodes,
    dbs = _ref.dbs;
  var arrayOfDBs = dbs.split(/[\s]*,[\s]*/);
  return !!intersection(arrayOfDBs, databaseCodes).length;
}
export function filterFunctionByMask(_ref2) {
  var mask = _ref2.mask,
    databaseMask = _ref2.databaseMask;
  // eslint-disable-next-line no-bitwise, eqeqeq
  return !mask || (mask & databaseMask) == databaseMask;
}
export function useFilterTabsFields(_ref3) {
  var items = _ref3.items,
    page = _ref3.page;
  var _useSelector = useSelector(selectSessionSettings),
    userMask = _useSelector.userMask;
  var databaseCodes = getSelectedDatabaseCodes(userMask);
  var newTabs = useMemo(function () {
    return Object.entries(items).reduce(function (total, _ref4) {
      var _ref5 = _slicedToArray(_ref4, 2),
        key = _ref5[0],
        _ref5$ = _ref5[1],
        _ref5$$fields = _ref5$.fields,
        fields = _ref5$$fields === void 0 ? [] : _ref5$$fields,
        filter = _ref5$.filter,
        rest = _objectWithoutProperties(_ref5$, _excluded);
      var filtered = fields.filter(function (field) {
        var _field$PAGE_MASKS$pag;
        var mask = (_field$PAGE_MASKS$pag = field[PAGE_MASKS[page]]) !== null && _field$PAGE_MASKS$pag !== void 0 ? _field$PAGE_MASKS$pag : field.mask;
        return (filter !== null && filter !== void 0 ? filter : filterFunctionByMask)(_objectSpread(_objectSpread({}, field), {}, {
          mask: mask,
          databaseMask: userMask,
          databaseCodes: databaseCodes
        }));
      });
      return _objectSpread(_objectSpread({}, total), {}, _defineProperty({}, key, _objectSpread(_objectSpread({}, rest), {}, {
        fields: filtered.length === 1 && filtered[0].code === "NO-LIMIT" ? [] : filtered
      })));
    }, {});
  }, [items, userMask, page]);
  return newTabs;
}