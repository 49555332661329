import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EvAuthApiApi } from "../../apiclient/api/EvAuthApiApi";
import pendoInit from "../../apiclient/pendo";

// This object contains all the potential values that thirdPartyScriptTimeout can be set
// according to the comElsevierEnv environment variable setting at build time
//
// note comElsevierEnv environment variable use is being removed, so same code can run in
// in mulitple enviroments.

//
//
// const extScriptTimeout = {
//   default: -1,
//   dev: 5000,
//   prod: 6000,
// };

var thirdPartyScriptTimeout = 6000;
var authApi = new EvAuthApiApi();
var callApiUser = function callApiUser() {
  return authApi.apiUser();
};
export var fetchUsersession = createAsyncThunk("usersession/get", callApiUser);
var initialState = {
  data: undefined,
  status: "init"
};
var usersessionSlice = createSlice({
  name: "usersession",
  initialState: initialState,
  reducers: {
    updateLocalUserSessionEntitlements: function updateLocalUserSessionEntitlements(state, action) {
      var _action$payload$userS;
      var ents = (_action$payload$userS = action.payload.userSessionData) === null || _action$payload$userS === void 0 ? void 0 : _action$payload$userS.userEntitlements;
      var updatedEntitlements = [].concat(_toConsumableArray(ents), [action.payload.entitlement]);

      // Create a new user session data object with the updated entitlements
      var updatedUserSessionData = _objectSpread(_objectSpread({}, action.payload.userSessionData), {}, {
        userEntitlements: updatedEntitlements
      });
      state.data = updatedUserSessionData;
      state.status = "done";
    },
    updateLocalUserSession: function updateLocalUserSession(state, _ref) {
      var payload = _ref.payload;
      state.data = payload;
      if (!payload) {
        state.status = "error";
      } else {
        state.status = "done";
        window.app = payload;
      }
    }
  },
  extraReducers: function extraReducers(builder) {
    builder.addCase(fetchUsersession.pending, function (state) {
      state.status = "pending";
    }).addCase(fetchUsersession.fulfilled, function (state, _ref2) {
      var payload = _ref2.payload;
      // When the API call is successful and we get some data,the data becomes the `fulfilled` action payload
      state.data = payload;
      if (!payload) {
        state.status = "needauth";
      } else {
        state.status = "done";

        // Pendo Initialization
        setTimeout(function () {
          pendoInit(payload);
        }, thirdPartyScriptTimeout);
        window.app = payload;
      }
    }).addCase(fetchUsersession.rejected, function (state) {
      state.status = "error";
    });
  }
});
var _usersessionSlice$act = usersessionSlice.actions,
  updateLocalUserSessionEntitlements = _usersessionSlice$act.updateLocalUserSessionEntitlements,
  updateLocalUserSession = _usersessionSlice$act.updateLocalUserSession;
export { updateLocalUserSessionEntitlements, updateLocalUserSession };
export var selectUsersession = function selectUsersession(state) {
  return state.usersession;
};
export var selectUsersessionData = function selectUsersessionData(state) {
  var _state$usersession;
  return (_state$usersession = state.usersession) === null || _state$usersession === void 0 ? void 0 : _state$usersession.data;
};
export var selectUsersessionFences = function selectUsersessionFences(state) {
  var _state$usersession2, _state$usersession2$d;
  return (_state$usersession2 = state.usersession) === null || _state$usersession2 === void 0 ? void 0 : (_state$usersession2$d = _state$usersession2.data) === null || _state$usersession2$d === void 0 ? void 0 : _state$usersession2$d.enabledFences;
};
export default usersessionSlice.reducer;