import { useEffect, useState } from "react";
function useMount() {
  var _useState = useState(false),
    mount = _useState[0],
    setMount = _useState[1];
  useEffect(function () {
    setMount(true);
  }, []);
  return mount;
}
export default useMount;