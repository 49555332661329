import _toConsumableArray from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
export var arraysAreEqual = function arraysAreEqual(arr1, arr2) {
  var set2 = new Set(arr2);
  return arr1.length === arr2.length && arr1.every(function (element) {
    return set2.has(element);
  });
};
export function arrayDelta(arr1, arr2) {
  var set1 = new Set(arr1);
  var set2 = new Set(arr2);
  return _toConsumableArray(set1).filter(function (x) {
    return !set2.has(x);
  });
}