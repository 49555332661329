/* eslint-disable no-console */
import { isProduction } from "./tools";
var firstColor = "#AAAAAA";
var secondColor = "#0ABF5F";
var warningColor = "#FBAB14";
var errorColor = "red";
var Prefix = "Adobe-Analytics";
var Debugger = {
  flag: !isProduction(),
  on: function on() {
    Debugger.flag = true;
    Debugger.log("Debugging is on.", true);
  },
  off: function off() {
    Debugger.flag = false;
    Debugger.log("Debugging is off.", true);
  },
  log: function log(message) {
    var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (!force && !Debugger.flag) return;
    console.log("%c- ".concat(Prefix), "color:".concat(firstColor, "; font-weight:bold;"), message);
  },
  event: function event(data) {
    if (!Debugger.flag) return;
    var _window = window,
      pageData = _window.pageData;
    console.groupCollapsed("%c\u250C ".concat(Prefix, " %cEvent [%c").concat(data.event, "%c]"), "color:".concat(firstColor), "color:".concat(secondColor), "color:#222; font-weight: normal;", "color:".concat(secondColor));
    console.log("%c".concat(pageData ? "|" : "└", "  %cData"), "color:".concat(firstColor, "; font-weight:bold;"), "color:".concat(secondColor, "; font-weight:bold;"), data);
    if (pageData) {
      console.log("%c└  %cPageData", "color:".concat(firstColor, "; font-weight:bold;"), "color:".concat(secondColor, "; font-weight:bold;"), pageData);
    }
    console.groupEnd();
  },
  warn: function warn(message) {
    var _console;
    if (!Debugger.flag) return;
    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }
    (_console = console).log.apply(_console, ["%c- ".concat(Prefix, " %cWarning %c").concat(message), "color:".concat(firstColor, "; font-weight: bold;"), "color:".concat(warningColor, "; font-weight: bold;"), "color:#222; font-weight: normal;"].concat(rest));
  },
  error: function error(message, _error) {
    console.error("%c- ".concat(Prefix), "color:".concat(errorColor, "; font-weight:bold;"), message, _error);
  }
};
export default Debugger;