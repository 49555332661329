import { useSelector, useDispatch } from "react-redux";
import { updateLocalUserSessionEntitlements, selectUsersessionData, updateLocalUserSession } from "redux/reducers/userSessionReducer";
import useFeatureToggles from "hooks/useFeatureToggles";
import { EvAdditionalTemporaryEntitlements } from "apiclient/api/EvAdditionalTemporaryEntitlementsApi";
import { checkFeatureEntitlement } from "hooks/withFeatureEntitlements";
var additionalTemporaryEntitlementsApi = new EvAdditionalTemporaryEntitlements();
var AI_FEATURE = {
  entitlement: "AI",
  isValidURL: function isValidURL() {
    return window.location.href.includes("/app/search/quick/");
  }
};
export var fetchTemporaryEntitlements = function fetchTemporaryEntitlements() {
  var _userSessionData$user;
  var dispatch = useDispatch();
  var userSessionData = useSelector(selectUsersessionData);
  var aiFeatureEntitlement = checkFeatureEntitlement(AI_FEATURE.entitlement);
  var cpxEntitlementExists = userSessionData === null || userSessionData === void 0 ? void 0 : (_userSessionData$user = userSessionData.userEntitlements) === null || _userSessionData$user === void 0 ? void 0 : _userSessionData$user.find(function (entitlement) {
    return entitlement.name === "cpx";
  });

  // TODO: Check if user is China based
  if (useFeatureToggles("EV-AI-RANDOM-ACCESS") && !aiFeatureEntitlement && cpxEntitlementExists) {
    dispatch(updateLocalUserSessionEntitlements({
      userSessionData: userSessionData,
      entitlement: {
        entitlementType: "FEATURE",
        name: "AI",
        mask: 0
      }
    }));
    additionalTemporaryEntitlementsApi.fetchAdditionalTemporaryEntitlements().then(function (response) {
      if (response) dispatch(updateLocalUserSession(response));
    })["catch"](function (error) {
      console.error("additionalTemporaryEntitlementsApi response error: ".concat(error));
    });
  }
};