import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { handleEventData } from "./tools";
export function load(_ref) {
  var json = _ref.json,
    searchHistoryEntity = _ref.searchHistoryEntity;
  window.Analytics.push(_objectSpread({
    event: "newPage",
    page: {
      name: "record:detail",
      type: "CP-CA",
      sectionName: "record"
    }
  }, handleEventData(json, searchHistoryEntity)));
}
export function loadReferences(_ref2) {
  var json = _ref2.json,
    searchHistoryEntity = _ref2.searchHistoryEntity,
    refType = _ref2.refType;
  window.Analytics.push(_objectSpread({
    event: "newPage",
    page: {
      name: "record:".concat(refType),
      type: "CP-CI",
      sectionName: "record"
    }
  }, handleEventData(json, searchHistoryEntity)));
}