import { useDispatch, useSelector } from "react-redux";
import { actions, notifications } from "redux/reducers/notificationsReducer";
function createRandomId() {
  return Math.floor(Math.random() * 100000000);
}
function useNotify() {
  var _useSelector = useSelector(notifications),
    cache = _useSelector.cache;
  var dispatch = useDispatch();
  var send = function send(message, severity) {
    var payload = {
      message: message,
      severity: severity,
      id: createRandomId()
    };
    dispatch(actions.send(payload));
    setTimeout(function () {
      dispatch(actions.hide(payload.id));
      setTimeout(function () {
        dispatch(actions.remove(payload.id));
      }, 100);
    }, severity === "error" ? 8000 : 5000);
  };
  return {
    cache: cache,
    close: function close(id) {
      dispatch(actions.remove(id));
    },
    success: function success(message) {
      send(message, "success");
    },
    info: function info(message) {
      send(message, "info");
    },
    warning: function warning(message) {
      send(message, "warning");
    },
    error: function error(message) {
      send(message, "error");
    }
  };
}
export default useNotify;