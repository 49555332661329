var EVENTS = {
  startPageView: "search:quick",
  assistantPageView: "assistant:home",
  thumbsDownButton: "SurveySubmission",
  thumbsUpButtonCtaId: "pendo:ev assistant like:closeButtonX:",
  copySummaryButton: "copyToClipboard",
  viewAllReferencesButton: "contentView",
  abstractSummaryAccordionOpen: "contentView",
  footNoteReferenceOpened: "contentView",
  footNoteAbstractOpened: "contentView",
  controlledTermsOpened: "contentView"
};

// natural language search event
// Activity map requires the following
// defined as part of the link:
// data-aa-region="aa-link-to-ai"
// data-aa-name="Let's go!"
export function tryNLS() {
  window.Analytics.push({
    event: "buttonClick",
    eventName: "buttonClick",
    page: {
      name: EVENTS.startPageView
    }
  });
}
export function assistantPage() {
  window.Analytics.push({
    event: "newPage",
    page: {
      name: EVENTS.assistantPageView,
      type: "SP-SR",
      sectionName: "search",
      event: EVENTS.assistantPageView
    }
  });
}
export function genAISearch(search, _ref) {
  var numReferences = _ref.numReferences,
    searchType = _ref.searchType,
    searchOrigin = _ref.searchOrigin;
  window.Analytics.push({
    event: "genAIContentUpdated",
    eventName: "genAIContentUpdated",
    page: {
      name: EVENTS.assistantPageView
    },
    genAI: {
      input: search,
      inputSource: searchType,
      // Values: "user", "example link", "go deeper refinement"
      inputOrigin: searchOrigin,
      // Values: "search", "ai"
      answerDetails: "emptyanswer=".concat(!numReferences),
      // content: [`${content}`], // future requirements
      citationCount: numReferences
    }
  });
}
export function thumbsUpButton() {
  window.Analytics.push({
    event: "ctaImpression",
    eventName: "ctaImpression",
    cta: {
      ids: [EVENTS.thumbsUpButtonCtaId]
    }
  });
}
export function thumbsDownButton() {
  window.Analytics.push({
    event: EVENTS.thumbsDownButton,
    eventName: EVENTS.thumbsDownButton,
    survey: {
      type: "GenAI-Summary-BinaryScore",
      score: "1",
      comment: "",
      question: "ev assistant - Summary relevance",
      targeting: "EV AI Users"
    }
  });
}
export function copySummaryButton() {
  window.Analytics.push({
    event: EVENTS.copySummaryButton,
    eventName: EVENTS.copySummaryButton,
    content: [{
      type: "ev-ai:summary"
    }]
  });
}
export function viewAllReferencesButton(refs) {
  window.Analytics.push({
    event: EVENTS.viewAllReferencesButton,
    eventName: EVENTS.viewAllReferencesButton,
    content: refs,
    link: {
      location: "ev ai reference flyout",
      name: "view all references"
    }
  });
}
export function abstractSummaryAccordionOpen(ref) {
  window.Analytics.push({
    event: EVENTS.abstractSummaryAccordionOpen,
    eventName: EVENTS.abstractSummaryAccordionOpen,
    content: ref,
    link: {
      location: "ev ai reference flyout",
      name: "abstract dropdown"
    }
  });
}
export function footNoteReferenceOpened(ref) {
  window.Analytics.push({
    event: EVENTS.footNoteReferenceOpened,
    eventName: EVENTS.footNoteReferenceOpened,
    content: [{
      id: ref.workId
    }],
    link: {
      location: "ev ai footnote reference",
      name: "summary link"
    }
  });
}
export function footNoteAbstractOpened(ref) {
  window.Analytics.push({
    event: EVENTS.footNoteAbstractOpened,
    eventName: EVENTS.footNoteAbstractOpened,
    content: [{
      id: ref.workId
    }],
    link: {
      location: "ev ai footnote reference",
      name: "abstract dropdown"
    }
  });
}
export function controlledTermsOpened(ref) {
  window.Analytics.push({
    event: EVENTS.controlledTermsOpened,
    eventName: EVENTS.controlledTermsOpened,
    content: [{
      id: ref.workId
    }],
    link: {
      location: "ev ai reference flyout",
      name: "controlled terms dropdown"
    }
  });
}