import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createSlice } from "@reduxjs/toolkit";
export var initialStateBase = {
  index: 0,
  pageSize: 0,
  current: undefined,
  prev: undefined,
  next: undefined,
  searchId: undefined,
  searchType: undefined,
  totalCount: 0
};
var initialState = _objectSpread({
  status: "init"
}, initialStateBase);
var cspSearchScrollSlice = createSlice({
  name: "cspsearchscroll",
  initialState: initialState,
  reducers: {
    setCspSearchScrollState: function setCspSearchScrollState(state, action) {
      state.status = action.payload.status;
      state.index = action.payload.index;
      state.pageSize = action.payload.pageSize;
      state.current = action.payload.current;
      state.prev = action.payload.prev;
      state.next = action.payload.next;
      state.totalCount = action.payload.totalCount;
      state.searchId = action.payload.searchId;
      state.searchType = action.payload.searchType;
    }
  }
});
var setCspSearchScrollState = cspSearchScrollSlice.actions.setCspSearchScrollState;
export { setCspSearchScrollState };
export var selectCspSearchScrollState = function selectCspSearchScrollState(state) {
  return state.cspsearchscroll;
};
export var selectCspSearchScrollStatus = function selectCspSearchScrollStatus(state) {
  return state.cspsearchscroll.status;
};
export default cspSearchScrollSlice.reducer;