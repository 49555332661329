import _toConsumableArray from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
function extractJournal(docData) {
  var _docData$DOCUMENTPROP, _docData$DOCUMENTPROP2, _ref, _ref2, _ref3, _docData$DOCUMENTPROP3, _docData$DOCUMENTPROP4, _docData$DOCUMENTPROP5, _docData$DOCUMENTPROP6, _docData$DOCUMENTPROP7, _docData$DOCUMENTPROP8, _docData$DOCUMENTPROP9, _docData$DOCUMENTPROP10, _docData$DOCUMENTPROP11;
  // from SearchResultsFormatAction.populateJournalObject
  return {
    issn: (_docData$DOCUMENTPROP = (_docData$DOCUMENTPROP2 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP2 === void 0 ? void 0 : _docData$DOCUMENTPROP2.SN) !== null && _docData$DOCUMENTPROP !== void 0 ? _docData$DOCUMENTPROP : null,
    name: (_ref = (_ref2 = (_ref3 = (_docData$DOCUMENTPROP3 = (_docData$DOCUMENTPROP4 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP4 === void 0 ? void 0 : _docData$DOCUMENTPROP4.SO) !== null && _docData$DOCUMENTPROP3 !== void 0 ? _docData$DOCUMENTPROP3 : (_docData$DOCUMENTPROP5 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP5 === void 0 ? void 0 : _docData$DOCUMENTPROP5.MT) !== null && _ref3 !== void 0 ? _ref3 : (_docData$DOCUMENTPROP6 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP6 === void 0 ? void 0 : _docData$DOCUMENTPROP6.RIL) !== null && _ref2 !== void 0 ? _ref2 : (_docData$DOCUMENTPROP7 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP7 === void 0 ? void 0 : _docData$DOCUMENTPROP7.SE) !== null && _ref !== void 0 ? _ref : null,
    volumeNumber: (_docData$DOCUMENTPROP8 = (_docData$DOCUMENTPROP9 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP9 === void 0 ? void 0 : _docData$DOCUMENTPROP9.VOM) !== null && _docData$DOCUMENTPROP8 !== void 0 ? _docData$DOCUMENTPROP8 : null,
    issueNumber: (_docData$DOCUMENTPROP10 = (_docData$DOCUMENTPROP11 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP11 === void 0 ? void 0 : _docData$DOCUMENTPROP11.IS) !== null && _docData$DOCUMENTPROP10 !== void 0 ? _docData$DOCUMENTPROP10 : null
    // currently unused/unset Journal items
    // issueNumber: null,
    // section: null,
    // speciality: null,
  };
}

function processType(docType) {
  return docType.replaceAll(" ", "_");
}
function extractContent(docData) {
  var _docData$DOCUMENTPROP12, _docData$DOCUMENTPROP13, _ref4, _docData$DOCUMENTPROP14, _docData$DOCUMENTPROP15, _docData$DOCUMENTPROP16, _ref5, _docData$DOCUMENTPROP17, _docData$DOCUMENTPROP18, _docData$DOCUMENTOBJE, _docData$DOCUMENTOBJE2, _docData$DOCUMENTPROP19, _docData$DOCUMENTPROP20, _docData$DOCUMENTPROP21, _docData$DOCUMENTPROP22, _docData$DOCUMENTPROP23, _docData$DOCUMENTPROP24, _docData$DOCUMENTPROP25, _docData$DOCUMENTPROP26, _docData$DOCUMENTPROP27, _docData$DOCUMENTPROP28;
  var docType = processType((_docData$DOCUMENTPROP12 = (_docData$DOCUMENTPROP13 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP13 === void 0 ? void 0 : _docData$DOCUMENTPROP13.DT) !== null && _docData$DOCUMENTPROP12 !== void 0 ? _docData$DOCUMENTPROP12 : "Unknown");
  return {
    id: "docid:".concat(docData.DOC["DOC-ID"]),
    title: (_ref4 = (_docData$DOCUMENTPROP14 = (_docData$DOCUMENTPROP15 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP15 === void 0 ? void 0 : _docData$DOCUMENTPROP15.TI) !== null && _docData$DOCUMENTPROP14 !== void 0 ? _docData$DOCUMENTPROP14 : (_docData$DOCUMENTPROP16 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP16 === void 0 ? void 0 : _docData$DOCUMENTPROP16.TT) !== null && _ref4 !== void 0 ? _ref4 : "[No title]",
    isbn: (_ref5 = (_docData$DOCUMENTPROP17 = (_docData$DOCUMENTPROP18 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP18 === void 0 ? void 0 : _docData$DOCUMENTPROP18.BN13) !== null && _docData$DOCUMENTPROP17 !== void 0 ? _docData$DOCUMENTPROP17 : (_docData$DOCUMENTOBJE = docData.DOCUMENTOBJECTS) === null || _docData$DOCUMENTOBJE === void 0 ? void 0 : (_docData$DOCUMENTOBJE2 = _docData$DOCUMENTOBJE.CITEDBY) === null || _docData$DOCUMENTOBJE2 === void 0 ? void 0 : _docData$DOCUMENTOBJE2.BN) !== null && _ref5 !== void 0 ? _ref5 : null,
    issn: (_docData$DOCUMENTPROP19 = (_docData$DOCUMENTPROP20 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP20 === void 0 ? void 0 : _docData$DOCUMENTPROP20.SN) !== null && _docData$DOCUMENTPROP19 !== void 0 ? _docData$DOCUMENTPROP19 : null,
    linkOut: "elsevier:EV",
    publicationType: (_docData$DOCUMENTPROP21 = (_docData$DOCUMENTPROP22 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP22 === void 0 ? void 0 : _docData$DOCUMENTPROP22.DT) !== null && _docData$DOCUMENTPROP21 !== void 0 ? _docData$DOCUMENTPROP21 : null,
    publisher: (_docData$DOCUMENTPROP23 = (_docData$DOCUMENTPROP24 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP24 === void 0 ? void 0 : _docData$DOCUMENTPROP24.PN) !== null && _docData$DOCUMENTPROP23 !== void 0 ? _docData$DOCUMENTPROP23 : null,
    type: "ev:".concat(docType, ":scope-detailed:").concat(docData.DOC.DB.ID),
    volumeNumber: (_docData$DOCUMENTPROP25 = (_docData$DOCUMENTPROP26 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP26 === void 0 ? void 0 : _docData$DOCUMENTPROP26.VOM) !== null && _docData$DOCUMENTPROP25 !== void 0 ? _docData$DOCUMENTPROP25 : null,
    issueNumber: (_docData$DOCUMENTPROP27 = (_docData$DOCUMENTPROP28 = docData.DOCUMENTPROPERTIES) === null || _docData$DOCUMENTPROP28 === void 0 ? void 0 : _docData$DOCUMENTPROP28.IS) !== null && _docData$DOCUMENTPROP27 !== void 0 ? _docData$DOCUMENTPROP27 : null
  };
}
function extractSearchHistory(_ref6) {
  var sort = _ref6.sort,
    sortdir = _ref6.sortdir,
    resultscount = _ref6.resultscount,
    searchtype = _ref6.searchtype,
    section1 = _ref6.section1,
    searchWord1 = _ref6.searchWord1;
  var word = "";
  if (section1 === "NO-LIMIT") {
    word = "allfields";
  } else if (section1) {
    word = section1.toLowerCase();
  }
  return {
    sortType: "".concat(sort, "-").concat(sortdir),
    totalResults: resultscount !== null && resultscount !== void 0 ? resultscount : null,
    type: "ev:keyword:implicit:".concat(searchtype, " search"),
    criteria: "searchWord1 ? ".concat(word, " contains ").concat(searchWord1)
  };
}
export function handleEventData(docData, searchHistoryEntity) {
  var holdData = {
    content: [extractContent(docData)],
    journal: extractJournal(docData)
  };
  if (searchHistoryEntity && searchHistoryEntity.searchId) {
    holdData.search = extractSearchHistory(searchHistoryEntity);
  }
  return holdData;
}
export function transformFacets(object) {
  return Object.entries(object).reduce(function (total, _ref7) {
    var _ref8 = _slicedToArray(_ref7, 2),
      name = _ref8[0],
      values = _ref8[1];
    return [].concat(_toConsumableArray(total), [{
      name: name,
      values: Object.keys(values).filter(function (key) {
        return values[key];
      })
    }]);
  }, []);
}