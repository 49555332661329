import _objectWithoutProperties from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _excluded = ["enableStorage", "page"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createSlice } from "@reduxjs/toolkit";
import createStorage from "services/storage";
var Storage = createStorage("START-PAGE");
var initialState = {
  QuickAndExpert: {
    // databasemask: 0,
    // date: null,
    // languages: null,
    // doctypes: null,
    // sortby: null,
    // autostemming: false,
    // disciplines: null,
    // treatmenttypes: null,
  },
  Quick: {}
};
export var StartPageSlice = createSlice({
  name: "startPage",
  initialState: initialState,
  reducers: {
    sync: function sync(state, _ref) {
      var _ref$payload = _ref.payload,
        page = _ref$payload === void 0 ? state.page : _ref$payload;
      var storaged = Storage.get();
      if (page) {
        var _storaged$page;
        state[page] = (_storaged$page = storaged === null || storaged === void 0 ? void 0 : storaged[page]) !== null && _storaged$page !== void 0 ? _storaged$page : {};
      }
      state[page].isSynced = true;
    },
    setDefaults: function setDefaults(state, _ref2) {
      var _ref2$payload = _ref2.payload,
        page = _ref2$payload.page,
        defaults = _ref2$payload.defaults;
      var storaged = Storage.get();
      if (defaults && !(storaged !== null && storaged !== void 0 && storaged[page]) && (!(state !== null && state !== void 0 && state[page]) || Object.keys(defaults).some(function (key) {
        return !Object.keys(state[page]).includes(key);
      }))) {
        state[page] = _objectSpread(_objectSpread({}, defaults), state[page]);
      }
    },
    update: function update(state, _ref3) {
      var _ref3$payload = _ref3.payload,
        enableStorage = _ref3$payload.enableStorage,
        page = _ref3$payload.page,
        rest = _objectWithoutProperties(_ref3$payload, _excluded);
      state[page] = _objectSpread(_objectSpread({}, state[page]), rest);
      if (enableStorage) Storage.set(state);
    },
    clear: function clear(state, _ref4) {
      var _initialState$page;
      var _ref4$payload = _ref4.payload,
        page = _ref4$payload.page,
        enableStorage = _ref4$payload.enableStorage;
      state[page] = (_initialState$page = initialState[page]) !== null && _initialState$page !== void 0 ? _initialState$page : {};
      if (enableStorage) Storage.set(state);
    }
  }
});
var actions = StartPageSlice.actions;
export { actions };
export var pages = function pages(state) {
  return state.startPage;
};
export default StartPageSlice.reducer;