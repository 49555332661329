import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createSlice } from "@reduxjs/toolkit";
import createStorage from "services/storage";
var Storage = createStorage("PENDO-COUNT");
var initialState = {
  status: "init",
  entities: {},
  isSynced: false,
  useLocalStorage: false
};
var pendoCountSlice = createSlice({
  name: "pendoCountContainer",
  initialState: initialState,
  reducers: {
    useLocalStorage: function useLocalStorage(state, _ref) {
      var useLocalStorage = _ref.payload;
      state.useLocalStorage = useLocalStorage;
      if (useLocalStorage) {
        Storage.set(state);
      }
    },
    syncStorage: function syncStorage(state) {
      if (state.useLocalStorage) {
        var _storaged = Storage.get();
        if (_storaged) {
          state.entities = _objectSpread({}, _storaged.entities);
        }
      }
      state.isSynced = true;
    },
    setEntry: function setEntry(state, _ref2) {
      var _ref2$payload = _ref2.payload;
      _ref2$payload = _ref2$payload === void 0 ? {} : _ref2$payload;
      var key = _ref2$payload.key,
        value = _ref2$payload.value;
      if (state.entities[key]) {
        state.entities[key] = value;
      } else if (!state.entities[key]) {
        state.entities = _objectSpread(_objectSpread({}, state.entities ? state.entities : {}), {}, _defineProperty({}, key, value));
      }
      if (state.useLocalStorage) {
        Storage.set(state);
      }
    },
    deleteEntry: function deleteEntry(state, _ref3) {
      var key = _ref3.payload;
      delete state.entities[key];
      if (state.useLocalStorage) {
        Storage.set(state);
      }
    },
    clearEntries: function clearEntries(state) {
      state.entities = _objectSpread({}, initialState.entities);
      if (state.useLocalStorage) {
        Storage.set(state);
      }
      state.isSynced = true;
    }
  }
});
export var selectMapNumEntries = function selectMapNumEntries(state) {
  return Object.keys(state.pendoCount.entities).length;
};
export var selectMapEntries = function selectMapEntries(state) {
  return state.pendoCount.entities;
};
export var selectMapStatus = function selectMapStatus(state) {
  return state.pendoCount.entities.status;
};
export var selectMapKeys = function selectMapKeys(state) {
  return Object.keys(state.pendoCount.entities);
};
export var selectMapEntryExists = function selectMapEntryExists(state, key) {
  return !!state.pendoCount.entities[key];
};
var actions = pendoCountSlice.actions;
export { actions };
export default pendoCountSlice.reducer;