import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { useSelector } from "react-redux";
import { selectSessionSettings } from "redux/reducers/sessionSettingsReducer";
import useStartPageContext from "./useStartPageContext";
function useQuickAndExpertContext(props) {
  var _useSelector = useSelector(selectSessionSettings),
    userMask = _useSelector.userMask,
    sort = _useSelector.sort;
  var defaultState = {
    databasemask: userMask,
    sort: sort
  };
  // console.log("defaultState: ", defaultState);
  var getContext = useStartPageContext(_objectSpread({
    page: "QuickAndExpert",
    defaultState: defaultState
  }, props));
  var _getContext = getContext(),
    quickAndExpertState = _getContext.state,
    setQuickAndExpertState = _getContext.setState;
  return {
    quickAndExpertState: quickAndExpertState,
    setQuickAndExpertState: setQuickAndExpertState
  };
}
export default useQuickAndExpertContext;