import _toConsumableArray from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  cache: []
};
export var NotificationsSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    send: function send(state, _ref) {
      var payload = _ref.payload;
      state.cache = [].concat(_toConsumableArray(state.cache), [payload]);
    },
    remove: function remove(state, _ref2) {
      var payload = _ref2.payload;
      state.cache = _toConsumableArray(state.cache.filter(function (_ref3) {
        var id = _ref3.id;
        return id !== payload;
      }));
    },
    hide: function hide(state, _ref4) {
      var payload = _ref4.payload;
      var item = state.cache.find(function (_ref5) {
        var id = _ref5.id;
        return id === payload;
      });
      if (item) item.hide = true;
      state.cache = _toConsumableArray(state.cache);
    }
  }
});
var actions = NotificationsSlice.actions;
export { actions };
export var notifications = function notifications(state) {
  return state.notifications;
};
export default NotificationsSlice.reducer;