// Checks for valid inspec entitlement combinations
var validInspecEntitlements = function validInspecEntitlements(dbArrayNames) {
  var validEntitlements = false;
  var ins = dbArrayNames.includes("ins");
  var ibf = dbArrayNames.includes("ibf");
  var ibs = dbArrayNames.includes("ibs");
  // valid: <no inspec> | ins | ins,ibf | ins,ibs | ibs
  if (!ins && !ibf && !ibs || ins && !ibf && !ibs || ins && ibf && !ibs || ins && !ibf && ibs || !ins && !ibf && ibs) {
    validEntitlements = true;
  }
  return validEntitlements;
};

// Checks for valid compendex entitlement combinations
var validCompendexEntitlements = function validCompendexEntitlements(dbArrayNames) {
  var validEntitlements = false;
  var cpx = dbArrayNames.includes("cpx");
  var c84 = dbArrayNames.includes("c84");
  var zbf = dbArrayNames.includes("zbf");
  // valid: <no compendex> | cpx | cpx,c84 | cpx,zbf | zbf
  if (!cpx && !c84 && !zbf || cpx && !c84 && !zbf || cpx && c84 && !zbf || cpx && !c84 && zbf || !cpx && !c84 && zbf) {
    validEntitlements = true;
  }
  return validEntitlements;
};
export var validateDbEntitlements = function validateDbEntitlements(userEntitlements) {
  var validEntitlements = true;
  if (userEntitlements) {
    var _dbArray = userEntitlements.filter(function (entitlement) {
      return entitlement.entitlementType === "DATABASE";
    });
    var _dbArrayNames = _dbArray.map(function (db) {
      return db.name.toLowerCase();
    });
    // Check for no DB entitlements
    if (_dbArrayNames.length === 0) {
      validEntitlements = false;
    } else {
      // check for valid compendex entitlements
      validEntitlements = validCompendexEntitlements(_dbArrayNames);
      if (validEntitlements) {
        // check for valid inspec entitlements
        validEntitlements = validInspecEntitlements(_dbArrayNames);
      }
    }
  } else {
    validEntitlements = false;
  }
  return validEntitlements;
};