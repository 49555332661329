import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
// strip out markup html tags from controlled term if needed
var cleanControlledTerm = function cleanControlledTerm(term) {
  var holdTerm = term;
  if (holdTerm === null) return null;
  // remove beginning mark tags
  holdTerm = holdTerm.replace(/(<mark([^>]+)>)/gi, "");
  // remove ending mark tags
  holdTerm = holdTerm.replace(/(<\/mark([^>]?)>)/gi, "");
  return holdTerm;
};
export function linkOut(content) {
  window.Analytics.push(_objectSpread({
    event: "linkOut"
  }, content));
}
export function searchResultsClick(content) {
  window.Analytics.push(_objectSpread({
    event: "searchResultsClick"
  }, content));
}
export function clickControlledTerm(_ref) {
  var controlledTerm = _ref.controlledTerm;
  var cleanedControlledTerm = cleanControlledTerm(controlledTerm);
  window.Analytics.push({
    event: "buttonClick",
    buttonType: "ControlledTerm:".concat(cleanedControlledTerm)
  });
}