import { configureStore } from "@reduxjs/toolkit";
import conferenceEventsReducer from "redux/reducers/conferenceEventsReducer";
import startPageReducer from "redux/reducers/startPageReducer";
import notificationsReducer from "redux/reducers/notificationsReducer";
import userSessionReducer from "../reducers/userSessionReducer";
import basketStateReducer from "../reducers/basketStateReducer";
import folderStateReducer from "../reducers/folderStateReducer";
import searchHistoryReducer from "../reducers/searchHistoryReducer";
import searchScrollReducer from "../reducers/searchScrollReducer";
import savedSearchesReducer from "../reducers/savedSearchesReducer";
import userPreferencesReducer from "../reducers/userPreferencesReducer";
import sessionSettingsReducer from "../reducers/sessionSettingsReducer";
import cspReducer from "../reducers/cspReducer";
import cspSearchScrollReducer from "../reducers/cspSearchScrollReducer";
import pendoCountReducer from "../reducers/pendoCountReducer";
export var store = configureStore({
  reducer: {
    usersession: userSessionReducer,
    baskets: basketStateReducer,
    folders: folderStateReducer,
    searchhistory: searchHistoryReducer,
    searchscroll: searchScrollReducer,
    savedsearches: savedSearchesReducer,
    userPreferences: userPreferencesReducer,
    sessionSettings: sessionSettingsReducer,
    conferenceSeries: cspReducer,
    cspSearchScroll: cspSearchScrollReducer,
    conferenceEvents: conferenceEventsReducer,
    pendoCount: pendoCountReducer,
    startPage: startPageReducer,
    notifications: notificationsReducer
  },
  middleware: function middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false
    });
  }
});