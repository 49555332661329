import _toConsumableArray from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { useEffect, useRef } from "react";
import debounce from "./debounce";
function useDebounce(func, wait) {
  var dependencies = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var ref = useRef(debounce(func, wait));
  useEffect(function () {
    var _ref$current;
    (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.cancel();
    ref.current = debounce(func, wait);
  }, [wait].concat(_toConsumableArray(dependencies)));
  return ref.current;
}
export default useDebounce;