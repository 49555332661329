import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { handleEventData } from "./tools";
export function exportSubmit(content) {
  window.Analytics.push(_objectSpread({
    event: "contentExport",
    page: {
      name: "record:detail",
      type: "CP-CL",
      sectionName: "record"
    }
  }, content));
}
export function saveRecordToFolderSubmit(content) {
  window.Analytics.push(_objectSpread({
    event: "saveToList",
    page: {
      name: "personal:folder:save",
      type: "SP-SS",
      sectionName: "personal"
    }
  }, content));
}
export function saveRecordToFolderModal(json) {
  window.Analytics.push(_objectSpread({
    event: "newPage",
    page: {
      name: "personal:folder:save",
      type: "SP-SS",
      sectionName: "personal"
    }
  }, handleEventData(json)));
}
export function print(content) {
  window.Analytics.push(_objectSpread({
    event: "newPage",
    page: {
      location: "record:detail",
      name: "print:delivery:success",
      type: "MP-ER",
      sectionName: "EV:print"
    }
  }, content));
}