import { useEffect, useRef } from "react";
var useOnUnmount = function useOnUnmount(callback, dependencies) {
  var isUnmounting = useRef(false);
  useEffect(function () {
    return function () {
      isUnmounting.current = true;
    };
  }, []);
  useEffect(function () {
    return function () {
      if (isUnmounting.current) {
        callback();
      }
    };
  }, dependencies);
};
export default useOnUnmount;