import _typeof from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/typeof.js";
// ************************************* Redux Toolkit helper Function *****************************************  //
//                                                                                                                //
// This function is a simple helper function that is used by the Conference Series feature to convert any non-    //
// serializable data into plain object. In Conference Series these data are class instances. The function         //
// itterates an array or object recursively and in case of non serializable data it converts them to plain Object //
// This way we ensure that ONLY serializable data are stores in the Redux Store                                   //
//                                                                                                                //
// *************************************************************************************************************  //

/* eslint no-restricted-syntax: "off" */

var convertToPlainObject = function convertToPlainObject(value) {
  if (Array.isArray(value)) {
    return value.map(function (item) {
      return convertToPlainObject(item);
    });
  }
  if (_typeof(value) !== "object" || value === null) {
    return value;
  }
  if (value.constructor && value.constructor.name !== "Object") {
    var _plainObject2 = {};
    for (var _key in value) {
      if (Object.prototype.hasOwnProperty.call(value, _key)) {
        _plainObject2[_key] = convertToPlainObject(value[_key]);
      }
    }
    return _plainObject2;
  }
  var plainObject = {};
  for (var _key2 in value) {
    if (Object.prototype.hasOwnProperty.call(value, _key2)) {
      plainObject[_key2] = convertToPlainObject(value[_key2]);
    }
  }
  return plainObject;
};
export default convertToPlainObject;