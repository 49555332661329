function debounce(func, wait) {
  var _this = this;
  var timer;
  var debounced = function debounced() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    clearTimeout(timer);
    timer = setTimeout(function () {
      func.apply(_this, args);
      timer = undefined;
    }, wait);
  };
  debounced.cancel = function () {
    clearTimeout(timer);
    timer = undefined;
  };
  return debounced;
}
export default debounce;