import _slicedToArray from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
// ************************************* Parsing the OneTrust Cookie *******************************************  //
//                                                                                                                //
// Extract the Cookie from document.cookie. Check the "OptanonConsent="" value which contains all the infornation //
// from OneTrust. Under optanonParams.groups the 5 cookie categories are mentioned along with the user            //
// preferecens. Format ['1:1', '2:0', '3:0', '4:1', '5:0'] where 1st element is cookie category and 2nd is        //
// activation status. Result: cookieCategories is filtered to hold only the user selcted cookie categories        //
//                                                                                                                //
// *************************************************************************************************************  //
/* eslint no-shadow: "off", array-callback-return: "off" */

var extractCookieCategories = function extractCookieCategories(cookie) {
  try {
    var _cookieCategories = [];
    // Get the cookie string
    var _cookieString = cookie;
    // Find the OptanonConsent cookie
    var _optanonCookie = _cookieString ? _cookieString.split(";").find(function (cookie) {
      return cookie.trim().startsWith("OptanonConsent=");
    }) : "";
    // Remove "OptanonConsent=" since it is messing the parsing
    if (_optanonCookie) {
      _optanonCookie = _optanonCookie.replace("OptanonConsent=", "");
      // Extract the value of the OptanonConsent cookie
      var _optanonValue = _optanonCookie || null;
      // Parse the parameters of the OptanonConsent cookie
      var _optanonParams = {};
      if (_optanonValue) {
        _optanonValue.split("&").forEach(function (param) {
          var _param$split = param.split("="),
            _param$split2 = _slicedToArray(_param$split, 2),
            key = _param$split2[0],
            value = _param$split2[1];
          _optanonParams[key] = decodeURIComponent(value);
        });
      }
      // Now you can access the individual parameters of the OptanonConsent cookie using their keys
      if (_optanonParams.groups) {
        _optanonParams.groups.split(",").sort().map(function (group) {
          switch (group) {
            case "1:1":
              _cookieCategories.push("Strictly Necessary");
              break;
            case "2:1":
              _cookieCategories.push("Performance");
              break;
            case "3:1":
              _cookieCategories.push("Functional");
              break;
            case "4:1":
              _cookieCategories.push("Targeting");
              break;
            case "5:1":
              _cookieCategories.push("Social Media");
              break;
            default:
              console.warn("Unknown group:", group);
          }
        });
      }
    }
    // ************************************************************************************************************* //

    return _cookieCategories;
  } catch (e) {
    // In case of an error return empty array.
    console.error("An error has occured during the Cookie category extraction. Details: ", e);
    return [];
  }
};
export default extractCookieCategories;