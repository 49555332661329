import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var EVENTS = {
  autoSuggest: "ev:search:autosuggest",
  register: "ev:search:register",
  startPageView: "search:quick",
  databaseDrawerClicked: "database:selection"
};
export function autoSuggest(value) {
  window.Analytics.push({
    event: "searchFeatureClick",
    page: {
      name: EVENTS.autoSuggest,
      type: "CP-CI",
      sectionName: "ev:search"
    },
    search: {
      featureName: "".concat(EVENTS.autoSuggest, ":").concat(value ? "on" : "off")
    }
  });
}
export function register() {
  window.Analytics.push({
    event: "conversionDriverClick",
    eventName: EVENTS.register,
    page: {
      name: "personal:loginregister",
      type: "AP-UR",
      sectionName: "personal",
      event: EVENTS.register
    },
    conversionDriver: {
      name: "ev:register:button:click"
    }
  });
}
export function startPageView() {
  window.Analytics.push({
    event: "newPage",
    page: {
      name: EVENTS.startPageView,
      type: "SP-SR",
      sectionName: "search",
      event: EVENTS.startPageView
    }
  });
}
export function spGuiToggle() {
  window.Analytics.push({
    event: "buttonClick",
    buttonType: "gui_toggle:oldSPView"
  });
}
export function changeStartPage(content) {
  window.Analytics.push(_objectSpread({
    event: "searchResultsClick"
  }, content));
}
export function databaseDrawerClicked() {
  window.Analytics.push({
    event: "newPage",
    page: {
      name: EVENTS.databaseDrawerClicked,
      type: "CP-CI",
      sectionName: "ev:database"
    }
  });
}
export function exploreDatabases() {
  window.Analytics.push({
    event: "buttonClick",
    buttonType: "[database-explore]:[click]"
  });
}
export function applyDatabaseChanges() {
  window.Analytics.push({
    event: "buttonClick",
    buttonType: "[database-selection]:[apply]"
  });
}