var _this = this;
/* eslint-disable no-bitwise */
// Default end year - init from env variable if available, otherwise use current year
export var EndYear = process.env.NEXT_PUBLIC_END_YEAR || new Date().getFullYear();

/** ***********************************
  MASK INFO
************************************ */
/**
 * Mask object can be used to identify database acces for fields, navigators, etc.
 * Note that masks are shown in hex below indicating they can combined by bitwise operators.
 */
export var Mask = {
  cpx: 0x00000001,
  // 1
  ins: 0x00000002,
  // 2
  nti: 0x00000004,
  // 4
  c84: 0x00000020,
  // 32
  pch: 0x00000040,
  // 64
  chm: 0x00000080,
  // 128
  cbn: 0x00000100,
  // 256
  // elt: 0x00000400, // 1024
  // ept: 0x00000800, // 2048
  ibf: 0x00001000,
  // 4096
  geo: 0x00002000,
  // 8192
  upt: 0x00080000,
  // 524288
  zbf: 0x00040000,
  // 262144
  grf: 0x00200000,
  // 2097152
  knc: 0x00400000,
  // 4194304
  kna: 0x00800000,
  // 8388608
  ibs: 0x00100000,
  // 1048576

  // Create expert search friendly database list from mask
  // Example (mask for all databases):  c,i,n,pc,cm,cb,el,ep,g,f,u,e,w,k
  expertMask: function expertMask(field) {
    var mask = [];
    if (!field || !field.mask) mask.push("");else {
      if (field.mask & Mask.cpxany) mask.push("c");
      if (field.mask & Mask.insany) mask.push("i");
      if (field.mask & Mask.pch) mask.push("pc");
      if (field.mask & Mask.chm) mask.push("cm");
      if (field.mask & Mask.cbn) mask.push("cb");
      if (field.mask & Mask.geo) mask.push("g");
      if (field.mask & Mask.grf) mask.push("f");
      if (field.mask & Mask.upt) mask.push("p");
      if (field.mask & Mask.kn) mask.push("k");
    }
    return mask.join(", ");
  }
};
// Function to check if a mask is valid. A valid mask must contain ONLY a valid combinations of the mask values below. All other combinations are invalid.
export var isValidMask = function isValidMask(urlMask) {
  if (urlMask === 0) return false;
  var maskValues = Object.values({
    cpx: Mask.cpx,
    ins: Mask.ins,
    nti: Mask.nti,
    c84: Mask.c84,
    pch: Mask.pch,
    chm: Mask.chm,
    cbn: Mask.cbn,
    ibf: Mask.ibf,
    geo: Mask.geo,
    upt: Mask.upt,
    zbf: Mask.zbf,
    grf: Mask.grf,
    knc: Mask.knc,
    kna: Mask.kna,
    ibs: Mask.ibs
  });
  var combinedMask = 0;
  // Combine all mask values
  // eslint-disable-next-line no-restricted-syntax
  for (var _i = 0, _maskValues = maskValues; _i < _maskValues.length; _i++) {
    var _value = _maskValues[_i];
    combinedMask |= _value;
  }

  // Check if urlMask contains only valid combinations of the mask values
  return (urlMask & combinedMask) === urlMask;
};
export var getDatabaseList = function getDatabaseList(mask) {
  var holdDatabaseList = [];
  if (mask) {
    if (mask & Mask.cpx) holdDatabaseList.push(Mask.cpx);
    if (mask & Mask.c84) holdDatabaseList.push(Mask.c84);
    if (mask & Mask.zbf) holdDatabaseList.push(Mask.zbf);
    if (mask & Mask.ins) holdDatabaseList.push(Mask.ins);
    if (mask & Mask.ibs) holdDatabaseList.push(Mask.ibs);
    if (mask & Mask.ibf) holdDatabaseList.push(Mask.ibf);
    if (mask & Mask.nti) holdDatabaseList.push(Mask.nti);
    if (mask & Mask.pch) holdDatabaseList.push(Mask.pch);
    if (mask & Mask.chm) holdDatabaseList.push(Mask.chm);
    if (mask & Mask.cbn) holdDatabaseList.push(Mask.cbn);
    if (mask & Mask.geo) holdDatabaseList.push(Mask.geo);
    if (mask & Mask.grf) holdDatabaseList.push(Mask.grf);
    if (mask & Mask.upt) holdDatabaseList.push(Mask.upt);
    if (mask & Mask.knc) holdDatabaseList.push(Mask.knc);
    if (mask & Mask.kna) holdDatabaseList.push(Mask.kna);
  }
  return holdDatabaseList;
};
Mask.cpxany = Mask.cpx + Mask.c84 + Mask.zbf;
Mask.insany = Mask.ins + Mask.ibs + Mask.ibf;
Mask.kn = Mask.knc + Mask.kna;
/* prettier-ignore */
Mask.all = Mask.cpxany + Mask.insany + Mask.nti + Mask.pch + Mask.chm + Mask.cbn + Mask.geo + Mask.upt + Mask.grf + Mask.knc + Mask.kna;

/** ***********************************
  DATABASES INFO
************************************ */
/**
 * Please note that CPX and INS have 2 different backfile definitions,
 * one that is meant to be paired with the frontfile (c84 and ibf) and one that is meant to be standalone.
 * label : Label to be used for the database
 * displayCode : Display code for DB meant for short explanations
 * searchcode: Search code for Expert search db field, e.g. "cpx wn db"
 * startyear: Default start year for the databbase
 * startyeartzkey: If applicable, the key to find the start year override from Text Zone
 * endyear: End year for database (backfiles only).  If not present, use the EndYear constant.
 */
export var Databases = {
  cpx: {
    order: 1,
    label: "Compendex",
    mask: Mask.cpx,
    displaycode: "c",
    searchcode: "cpx",
    startyear: 1969,
    endyear: EndYear,
    description: "Comprised of journals, conference proceedings, dissertations, standards, books, and recently preprints, Compendex content is sourced from thousands of publishers from around the world, including major engineering societies such as IEEE, ASME, SAE and ACM."
  },
  c84: {
    order: 2,
    label: "Compendex backfile",
    mask: Mask.c84,
    displaycode: "c",
    searchcode: "c84",
    startyear: 1884,
    endyear: 1969,
    description: "Comprised of journals, conference proceedings, dissertations, standards, books, and recently preprints, Compendex content is sourced from thousands of publishers from around the world, including major engineering societies such as IEEE, ASME, SAE and ACM."
  },
  zbf: {
    order: 3,
    label: "Ei Backfile",
    mask: Mask.zbf,
    displaycode: "c",
    searchcode: "zbf",
    startyear: 1884,
    startyeartzkey: "COMPENDEX_SELECTED_START_YEAR",
    endyear: 1969,
    description: "Comprised of journals, conference proceedings, dissertations, standards, books, and recently preprints, Compendex content is sourced from thousands of publishers from around the world, including major engineering societies such as IEEE, ASME, SAE and ACM."
  },
  ins: {
    order: 4,
    label: "Inspec",
    mask: Mask.ins,
    displaycode: "i",
    searchcode: "ins",
    startyear: 1969,
    startyeartzkey: "INSPEC_SELECTED_START_YEAR",
    endyear: EndYear,
    description: "The Inspec database, created by The Institution of Engineering and Technology (IET), contains over 22 million records from across global publishers to deliver quality content to a wide range of research communities."
  },
  ibf: {
    order: 5,
    label: "Inspec",
    mask: Mask.ibf,
    displaycode: "i",
    searchcode: "ibf",
    startyear: 1896,
    endyear: 1968,
    description: "The Inspec database, created by The Institution of Engineering and Technology (IET), contains over 22 million records from across global publishers to deliver quality content to a wide range of research communities."
  },
  ibs: {
    order: 6,
    label: "Inspec Archive",
    mask: Mask.ibs,
    startyeartzkey: "INSPEC_ARC_STAND_START_YEAR",
    displaycode: "ib",
    searchcode: "ibs",
    startyear: 1896,
    endyear: 1968,
    description: "The Inspec database, created by The Institution of Engineering and Technology (IET), contains over 22 million records from across global publishers to deliver quality content to a wide range of research communities."
  },
  nti: {
    order: 7,
    label: "NTIS",
    mask: Mask.nti,
    displaycode: "n",
    searchcode: "nti",
    startyear: 1899,
    startyeartzkey: "NTIS_SELECTED_START_YEAR",
    endyear: EndYear,
    description: "The National Technical Information Service (NTIS) database is the US Federal Government’s Clearinghouse for scientific, technical information (STEI) produced by or for Federal agencies, as such is the premier source of unclassified reports from the United States and international government agencies."
  },
  pch: {
    order: 8,
    label: "PaperChem",
    mask: Mask.pch,
    displaycode: "pc",
    searchcode: "pch",
    startyear: 1967,
    startyeartzkey: "PAPER_CHEM_SELECTED_START_YEAR",
    endyear: EndYear,
    description: "PaperChem is a database of abstracted and indexed literature created specifically for research in the pulp and paper industries and academic departments, covering chemistry and engineering to production technology, and indexed with the Thesaurus of Pulp and Paper."
  },
  chm: {
    order: 9,
    label: "Chimica",
    mask: Mask.chm,
    displaycode: "cm",
    searchcode: "chm",
    startyear: 1970,
    startyeartzkey: "CHIMICA_SELECTED_START_YEAR",
    endyear: EndYear,
    description: "Chimica provides chemistry and chemical engineering researchers with current and trustworthy information."
  },
  cbn: {
    order: 10,
    label: "CBNB",
    mask: Mask.cbn,
    displaycode: "cb",
    searchcode: "cbn",
    startyear: 1985,
    startyeartzkey: "CBNB_SELECTED_START_YEAR",
    endyear: EndYear,
    description: "Chemical Business NewsBase (CBNB) provides timely information for tracking market trends, commercial, and regulatory developments in the chemical and chemical engineering industry."
  },
  geo: {
    order: 11,
    label: "GEOBASE",
    mask: Mask.geo,
    displaycode: "g",
    searchcode: "geo",
    startyear: 1973,
    startyeartzkey: "GEOBASE_SELECTED_START_YEAR",
    endyear: EndYear,
    description: "GEOBASE is a database of indexed research covering relevant international geoscience and geography literature, with an emphasis on human and physical geography, ecology, geology, oceanography, geomechanics and international development studies/impacts."
  },
  grf: {
    order: 12,
    label: "GeoRef",
    mask: Mask.grf,
    displaycode: "f",
    searchcode: "grf",
    startyear: 1666,
    startyeartzkey: "GEOREF_SELECTED_START_YEAR",
    endyear: EndYear,
    description: "Produced by the American Geosciences Institute (AGI), GeoRef is focused on comprehensive coverage of geology and geoscience literature and is the most in-depth A&I database available for the geoscience literature and research."
  },
  upt: {
    order: 13,
    label: "Patents Plus",
    mask: Mask.upt,
    displaycode: "p",
    searchcode: "upt",
    startyear: 1790,
    startyeartzkey: "US_PATENTS_SELECTED_START_YEAR",
    endyear: EndYear,
    description: "Patents Plus is an integrated database that allows researchers to explore scientific and technical information from patent authorities including China (CN), Europe (EP), Germany (DE), Japan (JP), the United Kingdom (GB), the United States (US), and the World Intellectual Property Organization – WIPO (WO)."
  },
  knc: {
    order: 17,
    label: "Knovel",
    mask: Mask.knc,
    displaycode: "k",
    searchcode: "knc",
    startyear: 1969,
    endyear: EndYear,
    description: "Knovel provides precise answers faster with high-quality reference content, text and numeric search, interactive tools, and desktop and mobile options."
  },
  kna: {
    order: 18,
    label: "Knovel",
    mask: Mask.kna,
    displaycode: "k",
    searchcode: "kna",
    startyear: 1969,
    endyear: EndYear,
    description: "Knovel provides precise answers faster with high-quality reference content, text and numeric search, interactive tools, and desktop and mobile options."
  },
  getStartYear: function getStartYear(mask) {
    var startyear = new Date().getFullYear();
    Object.values(_this).forEach(function (value) {
      if (value.mask && value.startyear && value.mask & mask && value.startyear < startyear) startyear = value.startyear;
    });
    return startyear;
  },
  getMask: function getMask(code) {
    if (!code || !Databases[code]) return 0;
    return Databases[code].mask;
  },
  getLabel: function getLabel(code) {
    if (!code || !Databases[code]) return "";
    return Databases[code].label;
  },
  getSearchLinkMask: function getSearchLinkMask(code) {
    if (!code || !Databases[code]) return 0;
    if (this.isCPXDatabase(code)) {
      return Mask.cpx;
    }
    if (this.isINSDatabase(code)) {
      return Mask.ins;
    }
    return Databases[code].mask;
  },
  isPatentDatabase: function isPatentDatabase(code) {
    if (!code || !Databases[code]) return false;
    return code === this.upt.searchcode;
  },
  isNTISDatabase: function isNTISDatabase(code) {
    if (!code || !Databases[code]) return false;
    return code === this.nti.searchcode;
  },
  isCbnDatabase: function isCbnDatabase(code) {
    if (!code || !Databases[code]) return false;
    return code === this.cbn.searchcode;
  },
  isGeoDatabase: function isGeoDatabase(code) {
    if (!code || !Databases[code]) return false;
    return code === this.geo.searchcode;
  },
  isGrfDatabase: function isGrfDatabase(code) {
    if (!code || !Databases[code]) return false;
    return code === this.grf.searchcode;
  },
  isCPXDatabase: function isCPXDatabase(code) {
    if (!code || !Databases[code]) return false;
    return code === this.cpx.searchcode || code === this.c84.searchcode || code === this.zbf.searchcode;
  },
  isINSDatabase: function isINSDatabase(code) {
    if (!code || !Databases[code]) return false;
    return code === this.ins.searchcode || code === this.ibf.searchcode || code === this.ibs.searchcode;
  }
};
export var getAllDatabases = function getAllDatabases() {
  var holdDatabaseList = [];
  holdDatabaseList.push(Databases.cpx);
  holdDatabaseList.push(Databases.c84);
  holdDatabaseList.push(Databases.zbf);
  holdDatabaseList.push(Databases.ins);
  holdDatabaseList.push(Databases.ibf);
  holdDatabaseList.push(Databases.ibs);
  holdDatabaseList.push(Databases.nti);
  holdDatabaseList.push(Databases.pch);
  holdDatabaseList.push(Databases.chm);
  holdDatabaseList.push(Databases.cbn);
  holdDatabaseList.push(Databases.geo);
  holdDatabaseList.push(Databases.grf);
  holdDatabaseList.push(Databases.upt);
  holdDatabaseList.push(Databases.knc);
  holdDatabaseList.push(Databases.kna);
  return holdDatabaseList;
};

/* Returns an array of all of the databases contained within a given mask */
export var getSelectedDatabases = function getSelectedDatabases(mask) {
  var holdDatabaseList = [];
  getAllDatabases().forEach(function (value) {
    if (value.mask & mask) {
      // if both CPX and CPX backfile, just show CPX
      // if both INS and EI backfile, just show INS
      if (value.mask === Databases.zbf.mask && mask & Databases.cpx.mask || value.mask === Databases.ibs.mask && mask & Databases.ins.mask) {
        return;
      }
      holdDatabaseList.push(value);
    }
  });
  return holdDatabaseList;
};

/* Returns an array of all of the database masks contained within a given mask */
export var getSelectedDatabasesMasks = function getSelectedDatabasesMasks(mask) {
  var holdDatabaseList = [];
  getAllDatabases().forEach(function (value) {
    if (value.mask & mask) {
      // if both CPX and CPX backfile, just show CPX
      // if both INS and EI backfile, just show INS
      if (value.mask === Databases.zbf.mask && mask & Databases.cpx.mask || value.mask === Databases.ibs.mask && mask & Databases.ins.mask) {
        return;
      }
      holdDatabaseList.push(value.mask);
    }
  });
  return holdDatabaseList;
};

/* Updates the initial start years of each database object to the start year defined in a user's text zone */
export var updateStartYears = function updateStartYears(databases, userTextZones) {
  var holdDatabaseList = [];
  databases.forEach(function (db) {
    if (userTextZones[db.startyeartzkey]) {
      // eslint-disable-next-line no-param-reassign
      db.startyear = userTextZones[db.startyeartzkey];
      holdDatabaseList.push(db);
    }
  });
  return holdDatabaseList;
};
export function getSelectedDatabaseCodes(databases) {
  return getSelectedDatabases(databases).map(function (db) {
    return db.displaycode;
  });
}