import { transformFacets } from "./tools";
var EVENTS = {
  seriesLimitTo: "conference:series:limit:to",
  eventsLimitTo: "conference:events:limit:to",
  flyoutClicked: "conference:event:flyout:viewed",
  viewAllRecords: "conference:series:view:all:records"
};
function limitTo(pureFacets, page) {
  var facets = transformFacets(pureFacets);
  if (!facets.length) return;
  window.Analytics.push({
    event: "newPage",
    page: {
      name: page,
      type: "CP-CI",
      sectionName: "conference"
    },
    search: {
      facets: facets,
      type: page
    }
  });
}
export function seriesLimitTo(facets) {
  limitTo(facets, EVENTS.seriesLimitTo);
}
export function eventsLimitTo(facets) {
  limitTo(facets, EVENTS.eventsLimitTo);
}
export function flyoutClicked(_ref) {
  var code = _ref.code,
    name = _ref.name,
    publishername = _ref.publishername;
  if (!code) return;
  window.Analytics.push({
    event: "newPage",
    page: {
      name: EVENTS.flyoutClicked,
      type: "CP-CI",
      sectionName: "conference:event"
    },
    content: [{
      id: "".concat(code, ":").concat(name),
      type: "ev:".concat(EVENTS.flyoutClicked),
      publisher: publishername
    }]
  });
}
export function viewAllRecords(id) {
  if (!id) return;
  window.Analytics.push({
    event: "newPage",
    page: {
      name: EVENTS.viewAllRecords,
      type: "CP-CI",
      sectionName: "conference"
    },
    content: [{
      id: id,
      type: EVENTS.viewAllRecords
    }]
  });
}
export function fetchSeries() {
  window.Analytics.push({
    event: "newPage",
    page: {
      name: "conference:series",
      type: "CP-CA",
      sectionName: "conferenceseries"
    }
  });
}