function createStorage(prefix) {
  var check = function check() {
    return !!(typeof window === "undefined");
  };
  var keyGenerator = function keyGenerator() {
    var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
    return "EV-".concat(prefix).concat(key);
  };
  return {
    get: function get(key) {
      if (check()) return null;
      var data = localStorage.getItem(keyGenerator(key));
      return JSON.parse(data);
    },
    set: function set(item, key) {
      if (check()) return null;
      var data = JSON.stringify(item);
      return localStorage.setItem(keyGenerator(key), data);
    },
    remove: function remove(key) {
      if (check()) return null;
      return localStorage.removeItem(keyGenerator(key));
    }
  };
}
export default createStorage;