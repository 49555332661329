import { Mask } from "components/applogic/Databases";
export var Codes = [{
  label: "All fields",
  code: "NO-LIMIT",
  mask: 32828871 + Mask.upt
}, {
  label: "Subject/Title/Abstract",
  code: "KY",
  mask: 32828871 + Mask.upt
}, {
  label: "Abstract",
  code: "AB",
  mask: 32828871 + Mask.upt
}, {
  label: "Author",
  code: "AU",
  mask: 16000199
}, {
  label: "First author",
  code: "FIRSTAU",
  mask: 2106435
}, {
  label: "Author affiliation",
  code: "AF",
  mask: 2368711
}, {
  label: "Inventor",
  code: "AU",
  mask: 16828416 + Mask.upt
}, {
  label: "Assignee",
  code: "AF",
  mask: 16828416 + Mask.upt
}, {
  label: "Title",
  code: "TI",
  mask: 32828871 + Mask.upt
}, {
  label: "Standard ID",
  code: "STDID",
  mask: 1
}, {
  label: "ICS classification code",
  code: "ICS",
  mask: 1
}, {
  label: "Ei Classification code",
  code: "CL",
  mask: 262145
}, {
  label: "Geographic terms",
  code: "RGI",
  mask: 8192
}, {
  label: "Classification code",
  code: "CL",
  mask: 1056770
}, {
  label: "CODEN",
  code: "CN",
  mask: 2359299
}, {
  label: "Conference information",
  code: "CF",
  mask: 3408899
}, {
  label: "Collection title",
  code: "VT",
  mask: 2097152
},
// {label:'ISBN', code:'	BN',mask:	6291456},
{
  label: "ISBN",
  code: "	BN",
  mask: 14680064
}, {
  label: "Conference code",
  code: "CC",
  mask: 1
}, {
  label: "ISSN",
  code: "	SN",
  mask: 2106819
}, {
  label: "Main heading",
  code: "MH",
  mask: 294913 + Mask.upt
},
// {label:'Publisher', code:'PN',mask:	6292547},
{
  label: "Publisher",
  code: "PN",
  mask: 15991875
},
// {label:'Source title', code:'ST',mask:	6301123},
{
  label: "Source title",
  code: "ST",
  mask: 16000451
}, {
  label: "Patent number",
  code: "PM",
  mask: 19974144 + Mask.upt
}, {
  label: "Material Identity Number",
  code: "MI",
  mask: 2
},
// {label:'Ei controlled term', code:'CV',mask: 262144},
// {label:'Inspec controlled term', code:'CV',mask:	2},
// {label:'NTIS controlled term', code:'CV',mask:	4},
{
  label: "Controlled term",
  code: "CV",
  mask: 3451975 + Mask.upt
}, {
  label: "Subject Area",
  code: "CV",
  mask: 12582912
}, {
  label: "Uncontrolled term",
  code: "FL",
  mask: 2108487
}, {
  label: "Contract number",
  code: "CT",
  mask: 4
}, {
  label: "Country of origin",
  code: "CO",
  mask: 19193863 + Mask.upt
}, {
  label: "Monitoring agency",
  code: "AG",
  mask: 4
}, {
  label: "NTIS accession number",
  code: "AN",
  mask: 4
}, {
  label: "Publication date",
  code: "PD",
  mask: 16826368 + Mask.upt
}, {
  label: "Application number",
  code: "PAM",
  mask: 16826368 + Mask.upt
}, {
  label: "Priority number",
  code: "PRN",
  mask: 16826368 + Mask.upt
}, {
  label: "CAS registry number",
  code: "CR",
  mask: 3072
}, {
  label: "Patent country",
  code: "PC",
  mask: 2048
}, {
  label: "CPC code",
  code: "	PEC",
  mask: 16826370 + Mask.upt
}, {
  label: "IPC Code",
  code: "PID",
  mask: 16828418 + Mask.upt
}, {
  label: "Patent classification",
  code: "PUC",
  mask: Mask.upt
}, {
  label: "US classification",
  code: "PUC",
  mask: 32768
}, {
  label: "Report number",
  code: "RN",
  mask: 4
}, {
  label: "Funding number",
  code: "GFN",
  mask: 271555
}, {
  label: "Funding acronym",
  code: "GFA",
  mask: 271553
}, {
  label: "Funding sponsor",
  code: "GAG",
  mask: 271555
}, {
  label: "Funding information",
  code: "GFI",
  mask: 271555
}, {
  label: "Astronomical indexing",
  code: "AI",
  mask: 2
}, {
  label: "Category",
  code: "CAT",
  mask: 2097152
}];
export var Bools = [{
  label: "AND",
  code: "AND"
}, {
  label: "OR",
  code: "OR"
}, {
  label: "NOT",
  code: "NOT"
}];