import { useEffect } from "react";
function externalScripts(userSession) {
  // Scripts are stored in the userSession.userTextZones?.EXTERNAL_CUSTOMER_SCRIPT_1 //
  // set in CHIEF or loadRC.                                                         //
  useEffect(function () {
    try {
      if (userSession && Object.prototype.hasOwnProperty.call(userSession.userTextZones, "EXTERNAL_CUSTOMER_SCRIPT_1") && userSession.userTextZones.EXTERNAL_CUSTOMER_SCRIPT_1 != null) {
        var _scripts = userSession.userTextZones.EXTERNAL_CUSTOMER_SCRIPT_1;
        _scripts = JSON.parse(_scripts); // Parse the scripts since they are JSON.stringify encoded in load-rc
        console.log("scripts: ", _scripts);
        if (_scripts.length) {
          _scripts.forEach(function (script) {
            var element = document.createElement("script");
            Object.keys(script).forEach(function (key) {
              element[key] = script[key];
            });
            document.head.appendChild(element);
          });
        }
      }
    } catch (error) {
      console.error("VDOT: There was an error during the parsing of external user scripts", error);
    }
  }, [userSession]);
}
export default externalScripts;