import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUsersession } from "redux/reducers/userSessionReducer";
function useApp(callback) {
  var _useSelector = useSelector(selectUsersession),
    data = _useSelector.data,
    status = _useSelector.status;
  useEffect(function () {
    if (status === "done") callback(data);
  }, [status]);
}
export default useApp;